import RespondentFlowApi from "./respondentFlow.api";

const log = async (
  token,
  supplierToken,
  message,
  meta = "",
  campaignUid = ""
) => {
  let payload = {};
  payload.token = token;
  payload.supplier_token = supplierToken;
  payload.message = `CLIENT: ${message}`;
  payload.meta_data = meta;
  payload.campaign_uid = campaignUid;
  payload.log_time = new Date();

  try {
    await RespondentFlowApi.post("/v1/landing/save/log", payload);
  } catch (e) {
    console.error(e);
  }
};

const save_client_log = async (respondent, message, meta = {}) => {
  let payload = {};
  payload.token = respondent.token ?? "";
  payload.supplier_token = respondent.supplier_token ?? "";
  payload.message = message;
  payload.meta_data = meta ? JSON.stringify(meta) : "";
  payload.campaign_uid = respondent.campaign_uid ?? "";
  payload.log_time = new Date();

  try {
    await RespondentFlowApi.post("/v1/landing/save/log", payload);
  } catch (e) {
    console.error(e);
  }
};

const LoggerService = {
  log,
  save_client_log,
};

export default LoggerService;
