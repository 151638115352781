import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Ending from "./pages/Ending";
import ScreenQuestion from "./pages/ScreenQuestion";
import Success from "./pages/Success";
import ProfilerQuesion from "./pages/ProfilerQuesion";
import Error from "./pages/Error";
import Start from "./pages/Start";
import {
  LANDING_ROUTE,
  SCREENER_ROUTE,
  PROFILING_ROUTE,
  START_ROUTE,
  SUCCESS_ROUTE,
  ENDING_ROUTE,
  ERROR_ROUTE,
  TERMINATE,
  SECURITY_TERMINATE,
  OVER_QUOTA,
  CAMPAIGN_ROUTE,
} from "./constants/routes.constants";
import Terminate from "./pages/Terminate";
import OverQuota from "./pages/OverQuota";
import SecurityTerminate from "./pages/SecurityTerminate";
import Landing from "./pages/Landing";
import Campaign from "./pages/Campaign";

function App() {
  console.log("New Campaign deployment");
  return (
    <div className="App h-screen ">
      <BrowserRouter>
        <Routes>
          <Route path={LANDING_ROUTE} element={<Landing />} />
          <Route path={CAMPAIGN_ROUTE} element={<Campaign />} />
          <Route path={SCREENER_ROUTE} element={<ScreenQuestion />} />
          <Route path={PROFILING_ROUTE} element={<ProfilerQuesion />} />

          {/* start */}
          <Route path={START_ROUTE} element={<Start />} />
          <Route path={SUCCESS_ROUTE} element={<Success />} />
          <Route path={ENDING_ROUTE} element={<Ending />} />
          <Route path={ERROR_ROUTE} element={<Error />} />
          <Route path={TERMINATE} element={<Terminate />} />
          <Route path={OVER_QUOTA} element={<OverQuota />} />
          <Route path={SECURITY_TERMINATE} element={<SecurityTerminate />} />
          <Route path="*" element={<Navigate to={ERROR_ROUTE} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
