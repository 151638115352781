export const LANDING_ROUTE = "/l";
export const SCREENER_ROUTE = "/s";
export const PROFILING_ROUTE = "/p";
export const START_ROUTE = "/st";
export const SUCCESS_ROUTE = "/su";
export const ENDING_ROUTE = "/e";
export const ERROR_ROUTE = "/err";
export const TERMINATE = "/t";
export const OVER_QUOTA = "/oq";
export const SECURITY_TERMINATE = "/ste";
export const CAMPAIGN_ROUTE = "/c";
