import RespondentFlowApi from "./respondentFlow.api";

export const updateStatus = async (token, tokenId, status, subStatus) => {
  try {
    let reqObj = {
      token: token,
      tokenId: tokenId,
      status: status,
      sub_status: subStatus,
    };
    let data = await RespondentFlowApi.post(
      "/surveyLanding/v1/update/token/status",
      reqObj
    );
    if (data.result === "not_ok") {
      return;
    }
    return data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
