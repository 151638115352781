import { v4 as uuidv4 } from "uuid";

export const getVisiorData = () => {
  let visitorData = localStorage.getItem("rfVisitorData");
  if (!visitorData) {
    let visitorId = uuidv4();
    visitorData = JSON.stringify({ visitorId: visitorId });
    localStorage.setItem("rfVisitorData", visitorData);
  }
  return JSON.parse(visitorData);
};
