import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/ClipLoader";
import RespondentFlowApi from "../services/respondentFlow.api";
import LoggerService from "../services/logger.service";
import useQueryParams from "../utils/query.params";
import { ERROR_ROUTE, PROFILING_ROUTE } from "../constants/routes.constants";
import { getVisiorData } from "../services/visitor.service";
import { useMediaQuery } from "@mui/material";

const Landing = () => {
  const [Loader] = useState(true);
  const [paramActive, setParamActive] = useState(false);
  const [deviceType, setDeviceType] = useState(0);
  const IsMobile = useMediaQuery("(min-width:480px)");
  const IsTablet = useMediaQuery("(min-width:768px)");
  const isLaptopsAndLarger = useMediaQuery("(min-width:1200px)");

  useEffect(() => {
    if (isLaptopsAndLarger) {
      setDeviceType(1);
    } else if (IsTablet) {
      setDeviceType(3);
    } else if (IsMobile) {
      setDeviceType(2);
    } else {
      setDeviceType(2);
    }
  }, [IsTablet, isLaptopsAndLarger]);

  let params = {};

  useQueryParams().forEach((value, key) => {
    params[key] = value;
  });

  let navigate = useNavigate();
  useEffect(() => {
    if (params) {
      console.log("params", params);
      if (paramActive === false) setParamActive(true);
    } else {
      navigate(ERROR_ROUTE);
    }
    // eslint-disable-next-line
  }, [params]);

  let getStatus = async () => {
    let payload = { ...params };
    payload.device_type = deviceType;
    payload.fp_data = getVisiorData();
    payload.deviceType = deviceType; //TODO ASIF:: 1-Desktop, 2-Mobile, 3-Tablet
    payload.url = window.location.href;
    LoggerService.log(
      "",
      params.vq_token ?? "",
      "New Click received - " + payload.url
    );
    LoggerService.log(
      "",
      params.vq_token,
      "Calling landing register",
      JSON.stringify(payload)
    );
    try {
      let data = await RespondentFlowApi.post(`/v1/landing`, payload);
      LoggerService.log(
        data.token,
        data.vendorToken ?? "",
        "Click registered with token - " + data.token,
        JSON.stringify(data)
      );
      debugger;
      let obj = {};
      obj.paramList = data.paramList;
      obj.sessionUid = data.sessionUid;
      obj.surveyUid = data.surveyUid;
      obj.vendorUid = data.vendorUid;
      obj.token = data.token;
      obj.tokenId = data.tokenId;
      obj.userUid = data.userUid;
      obj.userId = data.userId;
      obj.traceId = data.traceId;
      obj.vendorToken = data.vendorToken;
      sessionStorage.removeItem("params");
      sessionStorage.setItem("params", JSON.stringify(obj));

      if (data.isExternalUrl === true) {
        LoggerService.log(
          data.token,
          data.vendorToken ?? "",
          "Respondent redirected to external link ",
          data.isExternalUrl
        );
        sessionStorage.removeItem("params");
        window.location.href = `${data.url}`;
      } else {
        LoggerService.log(
          data.token,
          data.vendorToken ?? "",
          "Respondent navigated to profiler",
          `${data.url}?token=${data.token}`
        );
        navigate(`${PROFILING_ROUTE}?token=${data.token}`);
      }
    } catch (e) {
      LoggerService.log(
        "",
        params.e_token ?? "",
        "Error on landing page",
        JSON.stringify(e)
      );
      navigate(`/error`);
    }
  };

  useEffect(() => {
    if (paramActive) getStatus();

    // eslint-disable-next-line
  }, [paramActive, deviceType]);

  return (
    <>
      {Loader ? (
        <>
          <div className="flex justify-center items-center h-screen bg-gray-200">
            <div>
              <BounceLoader
                color="rgb(233, 29, 37)"
                loading={true}
                size={60}
                textAlign="center"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <span
                style={{
                  fontWeight: "700",
                  marginTop: "20px",
                  display: "block",
                  textAlign: "center",
                  color: "rgb(233, 29, 37)",
                }}
              >
                {"Loading..."}
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex  justify-center">
            <img
              src="https://img.icons8.com/bubbles/256/man-with-a-clock.png"
              alt=""
            />
          </div>
        </>
      )}
    </>
  );
};

export default Landing;
