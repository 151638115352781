import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BounceLoader from "react-spinners/ClipLoader";
import RespondentFlowApi from "../services/respondentFlow.api";
import LoggerService from "../services/logger.service";
import useQueryParams from "../utils/query.params";
import { ERROR_ROUTE, PROFILING_ROUTE } from "../constants/routes.constants";
import { getVisiorData } from "../services/visitor.service";
import { useMediaQuery } from "@mui/material";

const Campaign = () => {
  const [Loader] = useState(true);
  const [paramActive, setParamActive] = useState(false);
  const [deviceType, setDeviceType] = useState(1);
  let params = {};
  const IsMobile = useMediaQuery("(min-width:480px)");
  const IsTablet = useMediaQuery("(min-width:768px)");
  const isLaptopsAndLarger = useMediaQuery("(min-width:1200px)");

  useEffect(() => {
    if (isLaptopsAndLarger) {
      setDeviceType(1);
    } else if (IsTablet) {
      setDeviceType(3);
    } else if (IsMobile) {
      setDeviceType(2);
    } else {
      setDeviceType(2);
    }
  }, [IsTablet, isLaptopsAndLarger]);
  // if (!localStorage.getItem("fpVisitorData")) {

  // } else {
  //   const data = JSON.parse(localStorage.getItem("fpVisitorData"));
  //   const isLoading = false;
  // }

  useQueryParams().forEach((value, key) => {
    params[key] = value;
  });

  let navigate = useNavigate();
  useEffect(() => {
    if (params) {
      if (paramActive === false) setParamActive(true);
    } else {
      navigate(ERROR_ROUTE);
    }
    // eslint-disable-next-line
  }, [params]);

  let getStatus = async () => {
    let payload = { ...params };
    payload.device_type = deviceType;
    payload.fp_data = getVisiorData();
    payload.deviceType = deviceType; //TODO ASIF:: 1-Desktop, 2-Mobile, 3-Tablet
    payload.url = window.location.href;
    if (params.vq_token && params.campaign_id)
      LoggerService.log(
        "",
        params.vq_token,
        "New Campaign Click received - " + payload.url,
        JSON.stringify(params),
        params.campaign_id
      );

    try {
      
      let data = await RespondentFlowApi.post(`/v1/campaign/landing`, payload);
      LoggerService.log(
        "",
        params.vq_token ?? "",
        "New Camapign click registered ",
        JSON.stringify(payload),
        params.campaign_id ?? ""
      );
      let obj = {};
      obj.paramList = data.paramList;
      obj.vendorUid = data.vendorUid;
      obj.vendorToken = data.vendorToken;
      obj.token = data.token;
      obj.tokenId = data.tokenId;
      obj.userUid = data.userUid;
      obj.userId = data.userId;
      obj.traceId = data.traceId;
      obj.campaignUid = data.campaignUid;
      obj.languageId = data.languageId;
      obj.vendorToken = data.vendorToken;
      sessionStorage.removeItem("params");
      sessionStorage.setItem("params", JSON.stringify(obj));

      if (data.isExternalUrl === true) {
        LoggerService.log(
          data.token,
          params.vq_token ?? "",
          "Respondent redirected to external link " + data.isExternalUrl,
          "",
          data.campaignUid
        );
        sessionStorage.removeItem("params");
        window.location.href = `${data.url}`;
      } else {
        LoggerService.log(
          data.token,
          params.vq_token ?? "",
          "New Version, redirecting to  " + `/p?cid=${data.campaignUid}`,
          "",
          data.campaignUid
        );
        LoggerService.log(
          data.token,
          params.vq_token ?? "",
          "Respondent redirected to  " +
            `${PROFILING_ROUTE}?cid=${data.campaignUid}`,
          "",
          data.campaignUid
        );
        navigate(`/p?token=${data.token}`);
      }
    } catch (e) {
      console.error(e);
      navigate(ERROR_ROUTE);
    }
  };

  useEffect(() => {
    if (paramActive) getStatus();

    // eslint-disable-next-line
  }, [paramActive, deviceType]);

  return (
    <>
      <div className="flex justify-center items-center h-screen bg-gray-200">
        <div>
          <BounceLoader
            color="rgb(233, 29, 37)"
            loading={true}
            size={60}
            textalign="center"
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <span
            style={{
              fontWeight: "700",
              marginTop: "20px",
              display: "block",
              textalign: "center",
              color: "rgb(233, 29, 37)",
            }}
          >
            {"Loading..."}
          </span>
        </div>
      </div>
    </>
  );
};

export default Campaign;
