import React from "react";
import QuesCard from "../components/QuesCard";
import "../components/question.css";
const ScreenQuestion = () => {
  return (
    <div className="bg-gray-200  h-[100vh] flex justify-center items-center p-2">
      <QuesCard />
    </div>
  );
};

export default ScreenQuestion;
