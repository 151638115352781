import React from "react";
import ErrorPng from "../assests/wrong.webp";
import ErrorIcon from "../assests/errorIcon.jpg";

const Error = ({ ErrorMessage }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textalign: "center",
        height: "90vh",
        width: "100vw",
        fontSize: "20px",
        fontWeight: "600",
      }}
    >
      {ErrorMessage === "loading" || !ErrorMessage ? (
        <>
          <img src={ErrorPng} alt="" />
          Oops! Somthing Went Wrong....
        </>
      ) : (
        <>
          <img src={ErrorIcon} alt="" height="200px" width="200px" />
          {ErrorMessage}
        </>
      )}
    </div>
  );
};

export default Error;
